import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'layout',
    redirect: 'index',
    component: () => import('../views/layout/index.vue'),
    children: [
      // 首页 ---- 素材库
      {
        path: '/index',
        name: 'home',
        component: () => import('../views/home/index.vue')
      },
      // vip设置页面
      {
        path: '/vipSetting',
        name: 'vipSetting',
        component: () => import('../views/home/vip_setting.vue')
      },
    ]
  },
  {
    path: '/h5/',
    name: 'layout',
    redirect: '/matenialDetail',
    component: () => import('../views/layout/index.vue'),
  },
  {
    path: '/guide',
    name: 'guide',
    component: () => import('../views/home/guide.vue'),
  },
  // 登录
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue')
  },
  // 注册
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/login/register.vue')
  },
  // 注册成功
  {
    path: '/registerSuccess',
    name: 'registerSuccess',
    component: () => import('../views/login/registerSuccess.vue')
  },
  // 发布
  {
    path: '/publish',
    name: 'publish',
    component: () => import('../views/home/publish.vue')
  },
  // 素材详情
  {
    path: '/matenialDetail',
    name: 'matenialDetail',
    component: () => import('../views/home/material_detail.vue')
  },
  // 分享出去的素材详情
  {
    path: '/matenialDetail/:pageType/:article_sn',
    name: 'matenialDetail',
    component: () => import('../views/home/material_detail.vue')
  },
  // // 分享访问首页
  // {
  //   path: '/matenial/share/:article_sn',
  //   name: 'shareHome',
  //   component: () => import('../views/home/share_home.vue')
  // },
  // 分享记录
  {
    path: '/shareRecordPage',
    name: 'shareRecord',
    component: () => import('../views/home/share_record.vue')
  },
  // 访问记录
  {
    path: '/accessRecord',
    name: 'accessRecord',
    component: () => import('../views/home/access_record.vue')
  },
  // 单人记录
  {
    path: '/singleAccessRecord',
    name: 'singleAccessRecord',
    component: () => import('../views/home/single_access_record.vue')
  },
  // 分享记录
  {
    path: '/shareRecord',
    name: 'shareRecord',
    component: () => import('../views/home/share_record.vue')
  },
  // 分享记录
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/home/search.vue')
  },
  // 修改手机
  {
    path: '/editMobile',
    name: 'editMobile',
    component: () => import('../views/home/editMobile.vue')
  },
  // 新手机号码
  {
    path: '/newMobile',
    name: 'newMobile',
    component: () => import('../views/home/newMobile.vue')
  },
  // 修改密码
  {
    path: '/bindMobile',
    name: 'bindMobile',
    component: () => import('../views/home/bindMobile.vue')
  },
  // 设置密码
  {
    path: '/setPassword',
    name: 'setPassword',
    component: () => import('../views/home/setPassword.vue')
  },
  // 意见反馈
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import('../views/home/feedback.vue')
  },

  // vip购买记录
  {
    path: '/buyLog',
    name: 'buyLog',
    component: () => import('../views/home/buy_log.vue')
  },
  // 收银台
  {
    path: '/submitPay',
    name: 'submitPay',
    component: () => import('../views/home/submit_pay.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/h5/', // process.env.BASE_URL,
  routes
})

// BAD
router.beforeEach((to, from, next) => {
  let pageType = to.query.pageType || to.params.pageType;
  // if (to.name == 'matenialDetail' && 'pcPreview, sharePage'.includes(pageType)) {
  //   document.title = '亿销宝';
  // } else {
  //   document.title = '亿销宝';
  // }
  // /index
  // /vipSetting
  if (to.path == "/index") {
    store.dispatch('setTabBarActive', 0)
  } else if (to.path == "/vipSetting") {
    store.dispatch('setTabBarActive', 1)
  }
  // 如果用户未能验证身份，则 `next` 会被调用两次
  next()
})

export default router
