/*
 * @Author: Mr.Cheng
 * @Date: 2023-12-06 15:06:09
 * @LastEditTime: 2023-12-11 12:02:38
 * @FilePath: \yixiaobao-h5\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import '@/assets/css/style.css'
import '@/assets/js/jquery-1.9.1.min.js';
import '@/assets/js/mobile.js';
import Request from '@/utils/request' // 引入封装的文件

import 'vant/lib/index.css';
import {
  PullRefresh, List, Image as VanImage, Icon, Dialog, Button, Divider, Toast, Overlay, Field, RadioGroup, Radio, Uploader,
  Loading, Empty, DatetimePicker, Popup, Step, Steps, ImagePreview, ShareSheet, Circle, SwipeCell, Cell
} from 'vant';

Vue.use(PullRefresh);
Vue.use(List);
Vue.use(VanImage);
Vue.use(Icon);
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Divider);
Vue.use(Toast);
Vue.use(Overlay);
Vue.use(Field);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Uploader);
Vue.use(Loading);
Vue.use(Empty);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Step);
Vue.use(Steps);
Vue.use(ImagePreview);
Vue.use(Circle);
Vue.use(ShareSheet);
Vue.use(SwipeCell);
Vue.use(Cell);

Vue.prototype.$http = Request.request // 挂载到全局上

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
