import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const PERSIST_PATHS = ['title', 'token', 'userInfo', 'dynamicUrl', 'mp_appid', 'xcx_h5_domain', 'tabBarActive'];

export default new Vuex.Store({
  state: {
    title: '',
    token: '',
    userInfo: {},

    dynamicUrl: '', // 动态接口域名
    mp_appid: '', // 动态公众号appid
    xcx_h5_domain: '', // h5访问地址

    tabBarActive: 0, // 选中的tabbar

    showVipTips: false, // 非会员时显示会员提示框
  },
  getters: {
  },
  mutations: {
    // 设置标题
    SET_TITLE(state, data) {
      state.title = data;
    },
    // 设置token
    SET_TOKEN(state, data) {
      state.token = data;
    },
    // 设置用户信息
    SET_USER_INFO(state, data) {
      state.userInfo = data;
    },

    // 设置动态接口域名
    SET_DYNAMIC_URL(state, data) {
      state.dynamicUrl = data;
    },
    // 设置动态公众号appid
    SET_MP_APPID(state, data) {
      state.mp_appid = data;
    },
    // 设置动态h5访问地址
    SET_XCX_H5_DOMAIN(state, data) {
      state.xcx_h5_domain = data;
    },

    // 设置选中的tabbar
    SET_TABBAR_ACTIVE(state, data) {
      state.tabBarActive = data;
    },
    // 设置非会员时显示会员提示框
    SET_SHOW_VIP_TIPS(state, data) {
      state.showVipTips = data;
    },
  },
  actions: {
    /**
     * 设置标题
     * @param {*} param0 
     * @param {*} data 
     */
    setTitle({ commit }, data) {
      commit('SET_TITLE', data);
    },
    /**
     * 设置token
     * @param {*} param0 
     * @param {*} data 
     */
    setToken({ commit }, data) {
      commit('SET_TOKEN', data);
    },
    /**
     * 设置用户信息
     * @param {*} param0 
     * @param {*} data 
     */
    setUserInfo({ commit }, data) {
      commit('SET_USER_INFO', data);
    },
    /**
     * 设置动态地址
     * @param {*} param0 
     * @param {*} data 
     */
    setDynamicUrl({ commit }, data) {
      commit('SET_DYNAMIC_URL', data);
    },
    /**
     * 设置动态公众号appid
     * @param {*} param0 
     * @param {*} data 
     */
    setMpAppid({ commit }, data) {
      commit('SET_MP_APPID', data);
    },
    /**
     * 设置动态h5访问地址
     * @param {*} param0 
     * @param {*} data 
     */
    setXcH5Domain({ commit }, data) {
      commit('SET_XCX_H5_DOMAIN', data);
    },
    /**
     * 设置动态h5访问地址
     * @param {*} param0 
     * @param {*} data 
     */
    setTabBarActive({ commit }, data) {
      commit('SET_TABBAR_ACTIVE', data);
    },
    /**
     * 设置非会员时显示会员提示框
     * @param {*} param0 
     * @param {*} data Boolean 必填
     */
    setShowVipTips({ commit }, data) {
      commit('SET_SHOW_VIP_TIPS', data);
    },
  },
  modules: {
  },
  plugins: [
    // 持久化储存插件
    createPersistedState({
      storage: window.localStorage,
      paths: PERSIST_PATHS
    }),
  ],
})
